import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { Card, FullPageLoading, LinkWithParams, PageTitle, SearchableList, tooltipStyles } from "components/utils/ui";
import { routes } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { convertToPrettyDateWithYear } from "helpers/date";
import { Tooltip } from "react-tooltip";

const Projects = () => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.projects;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const refreshProjects = () => {
        setLoaded(false);
        const getDataApiEndpoint = apiEndpoints.refreshProjects;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }

    const projectsTableHeaders = {
        name: t("name"),
        layoutStartDate: t("layoutStartDate"),
        location: t("location"),
        type: t("projectType")
    }

    const processProjects = (projects) => {
        return projects.map(project => {
            return {
                name: (<LinkWithParams baseUrl={routes.project.path} params={{id: project.id}} text={project.name}/>),
                layoutStartDate: convertToPrettyDateWithYear(project.layoutStartDate),
                location: project.location,
                type: project.type
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {t("projects")}
                </div>
                <div className="flex-grow-1"></div>
                <div className="clickable text-end text-decoration-none me-4">
                    <FontAwesomeIcon icon={faRefresh} className="text-color-accent"
                                     onClick={() => refreshProjects()} data-tooltip-id="refresh-tooltip"/>
                </div>
                <Tooltip
                    id={`refresh-tooltip`}
                    place="left"
                    content={t("refresh")}
                    style={tooltipStyles}
                />
                <div className="clickable text-end text-decoration-none">
                    <a data-tooltip-id="new-project-tooltip" href={process.env.REACT_APP_NEW_PROJECT_URL}
                       target="_blank">
                        <FontAwesomeIcon icon={faPlus} className="text-color-accent"/>
                    </a>
                </div>
                <Tooltip
                    id={`new-project-tooltip`}
                    place="left"
                    content={t("newProject")}
                    style={tooltipStyles}
                />
            </PageTitle>
            <Card>
                <SearchableList
                    loaded={loaded}
                    title={t("upcomingProjects")}
                    columnNames={projectsTableHeaders}
                    data={processProjects(data.projects)}
                />
            </Card>
        </>
    )
}

export { Projects };