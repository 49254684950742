import { authGroups } from "services/auth";

export const routes = {
    logoutSuccess: {
        path: `/logout-success`,
        component: `LogoutSuccess`
    },
    loginCallback: {
        path: `/login-callback`,
        component: `LoginCallback`
    },
    account: {
        path: `/account`,
        component: `Account`,
        authGroups: [authGroups.users]
    },
    systemStatus: {
        path: `/system-status`,
        component: `SystemStatus`,
        authGroups: [authGroups.users]
    },
    home: {
        path: `/`,
        component: `Home`,
        authGroups: [authGroups.users]
    },
    schedule: {
        path: `/schedule`,
        component: `Schedule`,
        authGroups: [authGroups.users]
    },
    fleet: {
        path: `/fleet/`,
        component: `Fleet`,
        authGroups: [authGroups.users]
    },
    robots: {
        path: `/fleet/robots`,
        component: `Robots`,
        authGroups: [authGroups.users]
    },
    robot: {
        path: `/fleet/robots/:id`,
        component: `Robot`,
        authGroups: [authGroups.users]
    },
    newRobot: {
        path: `/fleet/new-robot`,
        component: `NewRobot`,
        authGroups: [authGroups.fleetAdmins]
    },
    robotMaintenanceHistory: {
        path: `/fleet/robots/maintenance/history/:id`,
        component: `RobotMaintenanceHistory`,
        authGroups: [authGroups.users]
    },
    robotCalibrationHistory: {
        path: `/fleet/robots/calibration/history/:id`,
        component: `RobotCalibrationHistory`,
        authGroups: [authGroups.users]
    },
    updateRobotMaintenanceStatus: {
        path: `/fleet/robots/maintenance/update/:id`,
        component: `UpdateRobotMaintenanceStatus`,
        authGroups: [authGroups.fleetAdmins]
    },
    updateRobotCalibrationStatus: {
        path: `/fleet/robots/calibration/update/:id`,
        component: `UpdateRobotCalibrationStatus`,
        authGroups: [authGroups.fleetAdmins]
    },
    operations: {
        path: `/operations/`,
        component: `Operations`,
        authGroups: [authGroups.users]
    },
    operators: {
        path: `/operations/operators`,
        component: `Operators`,
        authGroups: [authGroups.users]
    },
    operator: {
        path: `/operations/operators/:id`,
        component: `Operator`,
        authGroups: [authGroups.users]
    },
    deployments: {
        path: `/operations/deployments`,
        component: `Deployments`,
        authGroups: [authGroups.users]
    },
    deployment: {
        path: `/operations/deployments/:id`,
        component: `Deployment`,
        authGroups: [authGroups.users]
    },
    newDeployment: {
        path: `/operations/new-deployment`,
        component: `NewDeployment`,
        authGroups: [authGroups.operationsAdmins]
    },
    assignment: {
        path: `/operations/assignment/:page/:id/:type?`,
        component: `Assignment`,
        authGroups: [authGroups.fleetAdmins, authGroups.operationsAdmins]
    },
    projects: {
        path: `/operations/projects`,
        component: `Projects`,
        authGroups: [authGroups.users]
    },
    project: {
        path: `/operations/projects/:id`,
        component: `Project`,
        authGroups: [authGroups.users]
    },
    reports: {
        path: `/reports/`,
        component: `Reports`,
        authGroups: [authGroups.fleetAdmins, authGroups.operationsAdmins, authGroups.businessAdmins]
    },
    cacheNotFound: {
        path: `/cache/*`,
        component: `FileNotFound`,
    },
    notFound: {
        path: `*`,
        component: `NotFound`
    },
};

export const routeWithParams = (path,
                                params) => {
    const filledPath = Object.keys(params).reduce((acc,
                                                   key) => {
            return acc.replace(`:${key}`,
                params[key]);
        },
        path);
    return filledPath.replace(/\/?:\w+/g,
        ``);
};
