export function convertToPrettyDate(date) {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        //console.error(`Invalid date format. Please use YYYY-MM-DD.`);
        return ``;
    }
    const [year, month, day] = date.split(`-`);
    const cleanedMonth = parseInt(month);
    const cleanedDay = parseInt(day);
    return `${cleanedMonth}/${cleanedDay}`;
}

export function convertToPrettyDateWithFullYear(date) {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        //console.error(`Invalid date format. Please use YYYY-MM-DD.`);
        return ``;
    }
    const [year, month, day] = date.split(`-`);
    const paddedMonth = month.padStart(2, `0`);
    const paddedDay = day.padStart(2, `0`);
    return `${paddedMonth}/${paddedDay}/${year}`;
}

export function convertToPrettyDateWithYear(date) {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        //console.error(`Invalid date format. Please use YYYY-MM-DD.`);
        return ``;
    }
    const [year, month, day] = date.split(`-`);
    const paddedMonth = month.startsWith(`0`) ? month.substring(1) : month;
    const paddedDay = day.startsWith(`0`) ? day.substring(1) : day;
    const shortYear = year.substring(2);
    return `${paddedMonth}/${paddedDay}/${shortYear}`;
}

export function dateObjectToDateString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

export function dateObjectToPrettyDate(date) {
    const month = date.getMonth() + 1; // getMonth() returns 0-based month index
    const day = date.getDate();
    return `${month}/${day}`;
}

export function dateObjectToPrettyDateWithFullYear(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, `0`);
    const day = String(date.getDate()).padStart(2, `0`);
    return `${month}/${day}/${year}`;
}

export function dateObjectToPrettyDateWithYear(date) {
    const year = date.getFullYear();
    const shortYear = year.toString().substring(2);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}/${shortYear}`;
}

function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
}

export function dateObjectToPrettyDateWithYearAndTime(date) {
    const year = date.getFullYear();
    const shortYear = year.toString().substring(2);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const time = formatAMPM(date);
    return `${month}/${day}/${shortYear} ${time}`;
}

export function dateObjectToPrettyDateWithFullYearAndTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, `0`);
    const day = String(date.getDate()).padStart(2, `0`);
    const time = formatAMPM(date);
    return `${month}/${day}/${year} ${time}`;
}

export function dateInRange(date, rangeStart, rangeEnd) {
    return date >= rangeStart && date <= rangeEnd;
}

export function dateRangesOverlap(startDate1, endDate1, startDate2, endDate2) {
    return startDate1 <= endDate2 && startDate2 <= endDate1;
}

export function today() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
}

export function getLastSundayDateObject() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = today.getDate() - dayOfWeek;
    const lastSunday = new Date(today.setDate(diff));
    lastSunday.setHours(0, 0, 0, 0);
    return lastSunday;
}