import React from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkWithParams } from "components/utils/ui";

const PageBackButton = ({className = "", baseUrl, params, children}) => {
    return (
        <LinkWithParams baseUrl={baseUrl} params={params} noTextDecoration>
            <div
                className={`align-items-center d-flex fw-normal text-color-accent text-decoration-none text-size-lg ${className}`}
                style={{textDecoration: "none !important"}}>
                <FontAwesomeIcon icon={faChevronLeft}/>
                <div className="ps-1">{children}</div>
            </div>
        </LinkWithParams>
    )
}

export { PageBackButton };