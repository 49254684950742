import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, ExternalLink, LoadingSpinner, PageTitle, TitleValueLine } from "components/utils/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { checkSystemStatus } from "services/systemStatus";

const SystemStatus = () => {
    const {t} = useTranslation();

    const [mainWebsiteStatus, setMainWebsiteStatus] = useState(undefined);
    const [authWebsiteStatus, setAuthWebsiteStatus] = useState(undefined);
    const [coreApiStatus, setCoreApiStatus] = useState(undefined);
    const [fssStatus, setFssStatus] = useState(undefined);

    useEffect(() => {
        /*checkSystemStatus(process.env.REACT_APP_MAIN_WEBSITE_HEARTBEAT_URL, setMainWebsiteStatus);*/
        checkSystemStatus(process.env.REACT_APP_AUTH_WEBSITE_HEARTBEAT_URL, setAuthWebsiteStatus);
        checkSystemStatus(process.env.REACT_APP_CORE_API_HEARTBEAT_URL, setCoreApiStatus);
        checkSystemStatus(process.env.REACT_APP_FSS_API_HEARTBEAT_URL, setFssStatus);
    }, []);

    const OnlineStatusLine = ({name, status, url = "", className}) => (
            <div className={`align-items-center d-flex ${className ? className : ``}`}>
                <div className="d-flex justify-content-center me-1" style={{width: "24px"}}>
                    {status === undefined ? (
                        <LoadingSpinner/>
                    ) : !status ? (
                        <FontAwesomeIcon icon={faTimes} className="text-danger" fixedWidth/>
                    ) : (
                        <FontAwesomeIcon icon={faCheck} className="text-success" fixedWidth/>
                    )}
                </div>
                <div className="me-2">{name}</div>
                {url && (<div className="text-color-med text-size-xs">{url}</div>)}
            </div>
        )
    ;

    return (
        <>
            <PageTitle>{t("systemStatus")}</PageTitle>
            <Card>
                <div className="fw-bold text-size-lg">{t("ourSystems")}</div>
                {/*<OnlineStatusLine
                    name={t("mainWebsite")}
                    status={mainWebsiteStatus}
                    url="www.rugged-robotics.com"
                    className="mt-2"
                />*/}
                <OnlineStatusLine
                    name={t("authWebsite")}
                    status={authWebsiteStatus}
                    url="auth.rugged-robotics.com"
                    className="mt-2"
                />
                <OnlineStatusLine
                    name="Portal"
                    status={true}
                    url="portal.rugged-robotics.com"
                />
                <OnlineStatusLine
                    name={t("coreApi")}
                    status={coreApiStatus}
                    className="mt-2"
                />
                <OnlineStatusLine
                    name={t("fileStorageSystemApi")}
                    status={fssStatus}
                />
            </Card>
            <Card>
                <div className="fw-bold text-size-lg">{t("otherSystems")}</div>
                <TitleValueLine
                    loaded={true}
                    title={`${t("microsoftAzure")}:`}
                    value={
                        <ExternalLink href="https://azure.status.microsoft/en-us/status" text={t("viewStatus")}/>
                    }
                    className="mt-2"
                />
                <TitleValueLine
                    loaded={true}
                    title={`${t("amazonAws")}:`}
                    value={
                        <ExternalLink href="https://health.aws.amazon.com/health/status" text={t("viewStatus")}/>
                    }
                />
                <TitleValueLine
                    loaded={true}
                    title={`${t("googleCloud")}:`}
                    value={
                        <ExternalLink href="https://status.cloud.google.com/" text={t("viewStatus")}/>
                    }
                />
            </Card>
        </>
    )
}

export { SystemStatus };