import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "components/utils/ui";
import { extractTextContent } from "helpers/searchAndFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const SearchableList = ({
                            loaded = true,
                            title = "",
                            columnNames,
                            data,
                            searchable = true,
                            sortable = true,
                            className = ""
                        }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [visibleColumns, setVisibleColumns] = useState(() => Object.keys(columnNames).reduce((acc, colId) => {
        acc[colId] = true;
        return acc;
    }, {}));
    const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);

    const columnSelectorRef = useRef(null);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSort = (colId) => {
        let direction = "asc";
        if (sortConfig.key === colId && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key: colId, direction: direction });
    };

    const handleColumnVisibilityChange = (colId) => {
        setVisibleColumns((prevVisibleColumns) => ({
            ...prevVisibleColumns,
            [colId]: !prevVisibleColumns[colId]
        }));
    };

    const sortedData = [...data].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = extractTextContent(a[sortConfig.key]).toLowerCase();
            const bValue = extractTextContent(b[sortConfig.key]).toLowerCase();
            if (aValue < bValue) {
                return sortConfig.direction === "asc" ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === "asc" ? 1 : -1;
            }
        }
        return 0;
    });

    const filteredData = sortedData.filter((item) => {
        return Object.values(item).some((val) => {
            const textContent = extractTextContent(val);
            return textContent.toLowerCase().includes(searchTerm.toLowerCase());
        });
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (columnSelectorRef.current && !columnSelectorRef.current.contains(event.target)) {
                setIsColumnSelectorOpen(false);
            }
        };

        if (isColumnSelectorOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isColumnSelectorOpen]);

    return (
        loaded ? (
            <div className={className}>
                <div className="d-flex">
                    {title && (<div className="col-6 fw-bold text-size-lg">{title}</div>)}
                    <div className={title ? "col-6" : "col-12"} style={{ position: "relative" }}>
                        {searchable && (
                            <div className="input-group input-group-focus">
                                <span className="input-group-text input-group-inner-focus" id="search-icon"
                                      style={{ backgroundColor: "var(--white)" }}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                                <input
                                    aria-label="Search"
                                    aria-describedby="search-icon"
                                    className="form-control input-group-inner-focus"
                                    onChange={handleSearch}
                                    placeholder="Search..."
                                    style={{ borderLeft: 0, paddingLeft: 0 }}
                                    type="text"
                                    value={searchTerm}
                                />
                                <span
                                    className="input-group-text input-group-inner-focus clickable"
                                    onClick={() => setIsColumnSelectorOpen(!isColumnSelectorOpen)}
                                    style={{ position: "relative", backgroundColor: "var(--white)" }}
                                >
                                    <FontAwesomeIcon icon={faFilter} />
                                </span>
                                {isColumnSelectorOpen && (
                                    <div
                                        ref={columnSelectorRef}
                                        style={{
                                            position: "absolute",
                                            top: "100%",
                                            right: 0,
                                            zIndex: 1000,
                                            backgroundColor: "var(--white)",
                                            border: "1px solid var(--light-xtrans)",
                                            padding: "8px 12px",
                                            borderBottomLeftRadius: "8px",
                                            borderBottomRightRadius: "16px",
                                            boxShadow: "var(--light-xtrans) 0px 0px 2px 0px, var(--light-trans) 0px 12px 24px -4px"
                                        }}
                                    >
                                        <div className="fw-bold mb-1">{t("columns")}</div>
                                        {Object.entries(columnNames).map(([colId, colName]) => (
                                            <div key={colId}>
                                                <input
                                                    type="checkbox"
                                                    id={`col-${colId}`}
                                                    checked={visibleColumns[colId]}
                                                    onChange={() => handleColumnVisibilityChange(colId)}
                                                />
                                                <label htmlFor={`col-${colId}`} className="ms-2">{colName}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="table-responsive mt-2">
                    <table className="table">
                        <thead>
                        <tr>
                            {Object.entries(columnNames).map(([colId, colName]) => (
                                visibleColumns[colId] && (
                                    <th
                                        key={colId}
                                        className={`${sortable ? `clickable user-select-none` : ``} fw-bold`}
                                        onClick={() => sortable ? handleSort(colId) : null}
                                    >
                                        <div className="align-items-center d-flex">
                                            <div>{colName}</div>
                                            {sortConfig.key === colId && (
                                                <FontAwesomeIcon
                                                    icon={sortConfig.direction === "asc" ? faChevronUp : faChevronDown}
                                                    className="ms-2 text-size-xs"
                                                />
                                            )}
                                        </div>
                                    </th>
                                )
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {filteredData.map((row, index) => (
                            <tr key={index}>
                                {Object.keys(columnNames).map((colId) => (
                                    visibleColumns[colId] && (
                                        <td key={`${index}-${colId}`} className="text-color-med">
                                            {row[colId]}
                                        </td>
                                    )
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        ) : (
            <div className={className}>
                <div className="d-flex">
                    {title && (<div className="col-6 fw-bold text-size-lg">{title}</div>)}
                </div>
                <LoadingSpinner size={48}></LoadingSpinner>
            </div>
        )
    );
};

SearchableList.propTypes = {
    loaded: PropTypes.bool,
    title: PropTypes.string,
    columnNames: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string
};

export { SearchableList };
