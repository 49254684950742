import { routes, routeWithParams } from "helpers/routes";
import { calendarPatterns } from "components/utils/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";

export const invisibleInputStyles = {
    background: "transparent",
    border: "0",
    color: "inherit",
    font: "inherit",
    margin: 0,
    outline: "none",
    padding: 0,
    width: "100%",
}

export const textColorForBackground = (bgColor) => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
        "#000000" : "#ffffff";
}

export const halfColor = (hex) => {
    if (!hex.startsWith("#")) {
        hex = `#${hex}`;
    }

    const hexToRGBA = (hex) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return {r, g, b, a: 0.5};
    };

    const blendWithWhite = (rgba) => {
        const blendChannel = (color, alpha) => {
            return Math.round((1 - alpha) * 255 + alpha * color);
        };
        const r = blendChannel(rgba.r, rgba.a);
        const g = blendChannel(rgba.g, rgba.a);
        const b = blendChannel(rgba.b, rgba.a);
        const componentToHex = (c) => {
            const hex = c.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        };
        return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
    };
    const rgbaColor = hexToRGBA(hex);
    return blendWithWhite(rgbaColor);
};


export const colors = {
    accent: "#f25c07",
    accentTrans: "#f8a089", //"#f25c0724", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    accentXtrans: "#fbf1eb", //"#f25c0708", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    black: "#000000",
    danger: "#dc3545",
    dangerSubtle: "#ea868f",
    dark: "#212b36",
    light: "#919eab",
    lightTrans: "#aab5bd", //"#919eab32", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    lightXtrans: "#d0d5da", //"#919eab16", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    lightXxtrans: "#e8ebed",
    med: "#637378",
    primary: "#0d6efd",
    primarySubtle: "#6ea8fe",
    success: "#198754",
    successSubtle: "#75b798",
    warning: "#ffc107",
    warningSubtle: "#ffda6a",
    white: "#ffffff"
}

export const tooltipStyles = {
    backgroundColor: "var(--dark)",
    borderRadius: "12px",
    color: "var(--white)",
    fontSize: "14px",
    padding: "4px 8px",
}

export const stateColors = {
    inMaintenance: "#dc3545",
    uncalibrated: "#dc3545",
    needsBlockingMaintenance: "#dc3545",
    pto: "#dc3545",
    inMaintenanceCalendar: '#ea868f',
    ptoCalendar: "#dc3545",
    inMaintenanceChart: '#ea868f',
    developmentReady: "#ffc107",
    inCalibration: "#ffc107",
    inCalibrationCheckout: "#ffc107",
    needsNonBlockingMaintenance: "#ffc107",
    uft: "#ffc107",
    developmentReadyCalendar: "#ffda6a",
    uftCalendar: "#ffc107",
    developmentReadyChart: "#ffda6a",
    jobReady: "#0d6efd",
    checkInTesting: "#0d6efd",
    calibrated: "#0d6efd",
    maintenanceNotRequired: "#0d6efd",
    inOffice: "#0d6efd",
    jobReadyCalendar: "#6ea8fe",
    jobReadyChart: "#6ea8fe",
    deployed: "#198754",
    deployedCalendar: "#198754",
    deployedChart: "#75b798",
}

export function styleCalendarData(e) {
    let calendarEvent = {
        name: e.name,
        url: e.link ? routeWithParams(routes.deployment.path, {id: e.link}) : null,
        travelStartDate: e.travelStartDate,
        startDate: e.startDate,
        endDate: e.endDate,
        travelEndDate: e.travelEndDate,
        row: e.belongsTo,
    };
    //deployment
    if (e.identifier === "scheduled") {
        calendarEvent.color = stateColors.deployedCalendar;
    }
    if (e.identifier === "tentative") {
        calendarEvent.color = stateColors.deployedCalendar;
        calendarEvent.outlineColor = stateColors.deployedCalendar;
        calendarEvent.textColor = colors.dark;
        calendarEvent.pattern = calendarPatterns.cross;
    }
    if (e.identifier === "placeholder") {
        calendarEvent.color = stateColors.deployedCalendar;
        calendarEvent.outlineColor = stateColors.deployedCalendar;
        calendarEvent.textColor = colors.dark;
        calendarEvent.pattern = calendarPatterns.blank;
    }
    //robot
    if (e.identifier === "inMaintenance") calendarEvent.color = stateColors.inMaintenanceCalendar;
    if (e.identifier === "developmentReady") calendarEvent.color = stateColors.developmentReadyCalendar;
    if (e.identifier === "jobReady") calendarEvent.color = stateColors.jobReadyCalendar;
    //staff
    if (e.identifier === "pto") calendarEvent.color = stateColors.ptoCalendar;
    if (e.identifier === "uft") calendarEvent.color = stateColors.uftCalendar;
    return calendarEvent;
}

export const DeploymentCalendarLegend = () => {
    const {t} = useTranslation();
    return (
        <div className="align-items-center d-flex mt-1">
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.deployedCalendar}}/>&nbsp;{t("deployed")}
        </div>
    );
}

export const RobotCalendarLegend = () => {
    const {t} = useTranslation();
    return (
        <div className="align-items-center d-flex flex-wrap mt-1">
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.deployedCalendar}}/>&nbsp;{t("deployed")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.jobReadyCalendar}}/>&nbsp;{t("jobReady")}
                <div className="me-4"></div>
            </div>
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.developmentReadyCalendar}}/>&nbsp;{t("developmentReady")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.inMaintenanceCalendar}}/>&nbsp;{t("inMaintenance")}
                <div className="me-4"></div>
            </div>
        </div>
    );
}

export const RobotPieLegend = () => {
    const {t} = useTranslation();
    return (
        <div className="align-items-center d-flex flex-wrap mt-3">
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.deployed}}/>&nbsp;{t("deployed")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.jobReady}}/>&nbsp;{t("jobReady")}
                <div className="me-4"></div>
            </div>
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.developmentReady}}/>&nbsp;{t("developmentReady")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.inMaintenance}}/>&nbsp;{t("inMaintenance")}
                <div className="me-4"></div>
            </div>
        </div>
    );
}

export const StaffCalendarLegend = () => {
    const {t} = useTranslation();
    return (

        <div className="align-items-center d-flex mt-1">
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.deployedCalendar}}/>&nbsp;{t("deployed")}
            <div className="me-4"></div>
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.uftCalendar}}/>&nbsp;{t("uft")}
            <div className="me-4"></div>
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.ptoCalendar}}/>&nbsp;{t("pto")}
            <div className="me-4"></div>
        </div>
    );
}