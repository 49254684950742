import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import {
    Card,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    SearchableList,
    tooltipStyles
} from "components/utils/ui";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeWithParams } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { dateObjectToPrettyDateWithYearAndTime } from "helpers/date";
import { useHotkeys } from "react-hotkeys-hook";
import { robotNavigationMap } from "components/utils/navigation";

const RobotCalibrationHistory = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useHotkeys("esc", () => navigate(routeWithParams(routes.robot.path, {id: id})));
    useHotkeys("shift+right", () => navigate(routeWithParams(routes.robotCalibrationHistory.path, {id: robotNavigationMap[id].next})));
    useHotkeys("shift+left", () => navigate(routeWithParams(routes.robotCalibrationHistory.path, {id: robotNavigationMap[id].prev})));
    useHotkeys("u", () => navigate(routeWithParams(routes.updateRobotCalibrationStatus.path, {id: id})));
    useHotkeys("m", () => navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: id})));

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.robotCalibrationHistory;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const robotCalibrationEventsTableHeaders = {
        timestamp: t("date"),
        status: t("status"),
        notes: t("notes"),
        updatedBy: t("updatedBy"),
        forwardDistancePairError1: t("forwardDistancePairError1"),
        forwardDistancePairError2: t("forwardDistancePairError2"),
        forwardShiftX: t("forwardShiftX"),
        forwardShiftY: t("forwardShiftY"),
        forwardShiftTheta: t("forwardShiftTheta"),
        backwardDistancePairError1: t("backwardDistancePairError1"),
        backwardDistancePairError2: t("backwardDistancePairError2"),
        backwardShiftX: t("backwardShiftX"),
        backwardShiftY: t("backwardShiftY"),
        backwardShiftTheta: t("backwardShiftTheta"),
        pinwheel1: t("pinwheel1"),
        pinwheel2: t("pinwheel2"),
        pinwheel3: t("pinwheel3"),
        plusVarForwardX: t("plusVarForwardX"),
        plusVarForwardY: t("plusVarForwardY"),
        plusVarReverseX: t("plusVarReverseX"),
        plusVarReverseY: t("plusVarReverseY"),
        targetVerificationPort: t("targetVerificationPort"),
        targetVerificationStarboard: t("targetVerificationStarboard"),
        targetAdditionRangeX: t("targetAdditionRangeX"),
        targetAdditionRangeY: t("targetAdditionRangeY"),
        printerCheck: t("printerCheck")
    }

    const processRobotCalibrationEvents = (events) => {
        return events.map(event => {
            const date = new Date(event.createdAt);
            return {
                timestamp: dateObjectToPrettyDateWithYearAndTime(date),
                status: t(`api:robot_calibration_status_list.${event.status}`),
                notes: event.notes,
                updatedBy: (<LinkWithParams baseUrl={routes.operator.path} params={{id: event.user.id}}
                                            text={event.user.name}/>),
                forwardDistancePairError1: Math.abs(event.forwardDistancePairError1) > 5 ? <div
                    className="fw-bold text-danger">{event.forwardDistancePairError1}</div> : event.forwardDistancePairError1,
                forwardDistancePairError2: Math.abs(event.forwardDistancePairError2) > 5 ? <div
                    className="fw-bold text-danger">{event.forwardDistancePairError2}</div> : event.forwardDistancePairError2,
                forwardShiftX: Math.abs(event.forwardShiftX) > 4.5 ?
                    <div className="fw-bold text-danger">{event.forwardShiftX}</div> : event.forwardShiftX,
                forwardShiftY: Math.abs(event.forwardShiftY) > 0.2 ?
                    <div className="fw-bold text-danger">{event.forwardShiftY}</div> : event.forwardShiftY,
                forwardShiftTheta: Math.abs(event.forwardShiftTheta) > 0.3 ?
                    <div className="fw-bold text-danger">{event.forwardShiftTheta}</div> : event.forwardShiftTheta,
                backwardDistancePairError1: Math.abs(event.backwardDistancePairError1) > 5 ? <div
                    className="fw-bold text-danger">{event.backwardDistancePairError1}</div> : event.backwardDistancePairError1,
                backwardDistancePairError2: Math.abs(event.backwardDistancePairError2) > 5 ? <div
                    className="fw-bold text-danger">{event.backwardDistancePairError2}</div> : event.backwardDistancePairError2,
                backwardShiftX: Math.abs(event.backwardShiftX) > 4.5 ?
                    <div className="fw-bold text-danger">{event.backwardShiftX}</div> : event.backwardShiftX,
                backwardShiftY: Math.abs(event.backwardShiftY) > 0.2 ?
                    <div className="fw-bold text-danger">{event.backwardShiftY}</div> : event.backwardShiftY,
                backwardShiftTheta: Math.abs(event.backwardShiftTheta) > 0.3 ?
                    <div className="fw-bold text-danger">{event.backwardShiftTheta}</div> : event.backwardShiftTheta,
                pinwheel1: Math.abs(event.pinwheel1) > 3 ?
                    <div className="fw-bold text-danger">{event.pinwheel1}</div> : event.pinwheel1,
                pinwheel2: Math.abs(event.pinwheel2) > 3 ?
                    <div className="fw-bold text-danger">{event.pinwheel2}</div> : event.pinwheel2,
                pinwheel3: Math.abs(event.pinwheel3) > 3 ?
                    <div className="fw-bold text-danger">{event.pinwheel3}</div> : event.pinwheel3,
                plusVarForwardX: Math.abs(event.plusVarForwardX) > 3 ?
                    <div className="fw-bold text-danger">{event.plusVarForwardX}</div> : event.plusVarForwardX,
                plusVarForwardY: Math.abs(event.plusVarForwardY) > 3 ?
                    <div className="fw-bold text-danger">{event.plusVarForwardY}</div> : event.plusVarForwardY,
                plusVarReverseX: Math.abs(event.plusVarReverseX) > 3 ?
                    <div className="fw-bold text-danger">{event.plusVarReverseX}</div> : event.plusVarReverseX,
                plusVarReverseY: Math.abs(event.plusVarReverseY) > 3 ?
                    <div className="fw-bold text-danger">{event.plusVarReverseY}</div> : event.plusVarReverseY,
                targetVerificationPort: Math.abs(event.targetVerificationPort) > 3 ? <div
                    className="fw-bold text-danger">{event.targetVerificationPort}</div> : event.targetVerificationPort,
                targetVerificationStarboard: Math.abs(event.targetVerificationStarboard) > 3 ? <div
                    className="fw-bold text-danger">{event.targetVerificationStarboard}</div> : event.targetVerificationStarboard,
                targetAdditionRangeX: Math.abs(event.targetAdditionRangeX) > 0.001 ? <div
                    className="fw-bold text-danger">{event.targetAdditionRangeX}</div> : event.targetAdditionRangeX,
                targetAdditionRangeY: Math.abs(event.targetAdditionRangeY) > 0.001 ? <div
                    className="fw-bold text-danger">{event.targetAdditionRangeY}</div> : event.targetAdditionRangeY,
                printerCheck: event.printerCheck
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1" baseUrl={routes.robot.path} params={{id: id}}>{id}</PageBackButton>
                    {loaded ? (
                        t("robotCalibrationHistory", {robot: id})
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
                {loaded && (
                    <>
                        <div className="clickable text-end text-decoration-none">
                            <LinkWithParams data-tooltip-id="update-tooltip"
                                            baseUrl={routes.updateRobotCalibrationStatus.path} params={{id: id}}>
                                <FontAwesomeIcon icon={faEdit} className="text-color-accent"/>
                            </LinkWithParams>
                        </div>
                        <Tooltip
                            id={`update-tooltip`}
                            place="left"
                            content={t("update")}
                            style={tooltipStyles}
                        />
                    </>
                )}
            </PageTitle>
            <Card>
                <SearchableList
                    loaded={loaded}
                    title={t("history")}
                    columnNames={robotCalibrationEventsTableHeaders}
                    data={processRobotCalibrationEvents(data.calibrationHistory)}
                    sortable={false}
                />
            </Card>
        </>
    )
}

export { RobotCalibrationHistory };