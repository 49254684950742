import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import {
    ActionButton,
    Calendar,
    Card,
    EditableTitleValueLine,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    MobileProviderSelector,
    PageBackButton,
    PageTitle,
    RobotCalendarLegend, RobotPieLegend,
    SearchableList,
    StaffSelector, stateColors,
    styleCalendarData,
    TitleValueLine,
    tooltipStyles
} from "components/utils/ui";
import { routes, routeWithParams } from "helpers/routes";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { faSlack } from "@fortawesome/free-brands-svg-icons";
import {
    convertToPrettyDate,
    convertToPrettyDateWithYear,
    dateObjectToDateString,
    dateObjectToPrettyDateWithYearAndTime,
    getLastSundayDateObject
} from "helpers/date";
import { faCheck, faEdit, faHistory, faPencilAlt, faRefresh, faRss, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { timeAgo } from "helpers/translations";
import { uniqueId } from "helpers/random";
import { camelCaseToWords } from "helpers/sentence";
import { useHotkeys } from "react-hotkeys-hook";
import { robotNavigationMap } from "components/utils/navigation";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip as RechartsTooltip } from "recharts";
import { generateEvenlySpacedColors } from "helpers/colors";


const Robot = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [error, setError] = useState(undefined);
    const [robotData, setRobotData] = useState(undefined);
    const [robotDataLoaded, setRobotDataLoaded] = useState(false);
    const [networkData, setNetworkData] = useState(undefined);
    const [networkDataLoaded, setNetworkDataLoaded] = useState(false);

    const [assignee, setAssignee] = useState(null)
    const [editingAssignee, setEditingAssignee] = useState(false)
    const [loadedAssignee, setLoadedAssignee] = useState(true)

    const [mobileProvider, setMobileProvider] = useState(null);
    const [editingMobileProvider, setEditingMobileProvider] = useState(false);
    const [loadedMobileProvider, setLoadedMobileProvider] = useState(true);

    const [historicalStatusProportionColors, setHistoricalStatusProportionColors] = useState([])

    const lastSunday = dateObjectToDateString(getLastSundayDateObject());

    useHotkeys("esc", () => navigate(routes.robots.path));
    useHotkeys("shift+right", () => navigate(routeWithParams(routes.robot.path, {id: robotNavigationMap[id].next})));
    useHotkeys("shift+left", () => navigate(routeWithParams(routes.robot.path, {id: robotNavigationMap[id].prev})));
    useHotkeys("m", () => navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: id})));
    useHotkeys("c", () => navigate(routeWithParams(routes.robotCalibrationHistory.path, {id: id})));

    useEffect(() => {
        const getRobotDataEndpoint = apiEndpoints.robot;
        const getNetworkDataEndpoint = apiEndpoints.robotNetwork;
        createEmptyApiResponse(`get`, getRobotDataEndpoint).then(emptyApiResponse => {
            if (!robotData && emptyApiResponse) setRobotData(emptyApiResponse);
        });
        createEmptyApiResponse(`get`, getNetworkDataEndpoint).then(emptyApiResponse => {
            if (!networkData && emptyApiResponse) setNetworkData(emptyApiResponse);
        });
        apiRequest(setRobotData, setError, `get`, getRobotDataEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setRobotDataLoaded(true);
        })
        apiRequest(setNetworkData, setError, `get`, getNetworkDataEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setNetworkDataLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!robotData) {
            setMobileProvider(robotData.hardware.mobileProvider);
            setAssignee(robotData.summary.assignee.id);
        }
    }, [robotData]);

    const updateRobot = async (field, value) => {
        const updateRobotApiEndpoint = apiEndpoints.robot;
        return await apiRequest(setRobotData, setError, `put`, updateRobotApiEndpoint, id, {[field]: value});
    };

    const refreshNetworkData = () => {
        setNetworkDataLoaded(false);
        const getNetworkDataEndpoint = apiEndpoints.robotNetwork;
        apiRequest(setNetworkData, setError, `get`, getNetworkDataEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setNetworkDataLoaded(true);
        })
    }

    const processOverlaidPackages = (packages) => {
        return (
            <>
                {packages.map((pkg, index) => {
                    const id = uniqueId();
                    return (
                        <div className="d-flex" key={pkg.package}>
                            <div data-tooltip-id={`overlay-package-${pkg.branch}-${id}`} data-tooltip-offset={4}>
                                {pkg.package}
                            </div>
                            <Tooltip
                                id={`overlay-package-${pkg.branch}-${id}`}
                                place="top"
                                effect="solid"
                                content={pkg.branch}
                                style={tooltipStyles}
                                opacity={1}
                            />
                            {index < packages.length - 1 && (<>,&nbsp;</>)}
                        </div>
                    );
                })}
            </>
        );
    }

    const processRuggedFirmware = (device, fw) => {
        const id = uniqueId();
        const date = new Date(fw.timestamp);
        const formattedDate = dateObjectToPrettyDateWithYearAndTime(date);
        return (
            <div className="d-flex" key={fw.package}>
                {fw.app && (
                    <>
                        <div data-tooltip-id={`firmware-${device}-app-${id}`} data-tooltip-offset={4}>
                            {fw.app}
                        </div>
                        <Tooltip
                            id={`firmware-${device}-app-${id}`}
                            place="top"
                            effect="solid"
                            content={t("appFirmwareAsOf", {time: formattedDate})}
                            style={tooltipStyles}
                            opacity={1}
                        />
                    </>
                )}
                {fw.app && fw.bootloader && (<>/</>)}
                {fw.bootloader && (
                    <>
                        <div data-tooltip-id={`firmware-${device}-bootloader-${id}`} data-tooltip-offset={4}>
                            {fw.bootloader}
                        </div>
                        <Tooltip
                            id={`firmware-${device}-bootloader-${id}`}
                            place="top"
                            effect="solid"
                            content={t("bootloaderFirmwareAsOf", {time: formattedDate})}
                            style={tooltipStyles}
                            opacity={1}
                        />
                    </>
                )}
                {!fw.app && !fw.bootloader && t("unknown")}
            </div>
        );
    }

    const assignmentsTableHeaders = {
        deployment: t("name"),
        travelDates: t("travelDates")

    }

    const processFutureDeployments = (assignments) => {
        return assignments.map(assignment => {
            return {
                deployment: (<LinkWithParams baseUrl={routes.deployment.path} params={{id: assignment.deployment.id}}
                                             text={assignment.deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDate(assignment.travelStartDate)}-${convertToPrettyDate(assignment.travelEndDate)}`,
            };
        });
    };

    const processPastDeployments = (assignments) => {
        return assignments.map(assignment => {
            return {
                deployment: (<LinkWithParams baseUrl={routes.deployment.path} params={{id: assignment.deployment.id}}
                                             text={assignment.deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDateWithYear(assignment.travelStartDate)}-${convertToPrettyDateWithYear(assignment.travelEndDate)}`,
            };
        });
    };

    const processCalendarData = (calendarData) => {
        return calendarData.map(e => styleCalendarData(e));
    };

    const processHistoricalStatusProportionData = (historicalStatusProportions) => {
        if (!historicalStatusProportions) return [];
        return Object.keys(historicalStatusProportions).map(status => {
            return {
                name: status,
                value: historicalStatusProportions[status]
            };
        });
    };

    useEffect(() => {
        if (!!robotData && !!robotData.historicalStatusProportions) setHistoricalStatusProportionColors(generateEvenlySpacedColors(Object.keys(robotData.historicalStatusProportions).length))
    }, [robotData]);

    const HistoricalStatusProportionsTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={tooltipStyles}>
                    <div className="fw-bold text-size-lg">{label}</div>
                    {payload.map((entry, index) => (
                        <div key={`historical-status-chart-tooltip-item-${index}`}>
                            {`${t(`api:robot_status_list.${entry.name}`)}: ${entry.value.toFixed(1)}%`}
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    };

    if (robotData === undefined || networkData === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1" baseUrl={routes.robots.path}>{t("robots")}</PageBackButton>
                    {robotDataLoaded ? (
                        t("robotStatus", {robot: robotData.summary.name})
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
                {robotDataLoaded && (
                    <>
                        <div className="clickable text-end text-decoration-none">
                            <FontAwesomeIcon
                                data-tooltip-id="live-status-tooltip"
                                icon={faRss}
                                className="text-color-accent"
                                onClick={() => window.alert(t("comingSoon"))}
                            />
                        </div>
                        <Tooltip
                            id={`live-status-tooltip`}
                            place="left"
                            content={t("robotLiveStatus", {robot: robotData.summary.name})}
                            style={tooltipStyles}
                        />
                    </>
                )}
            </PageTitle>
            <ResponsiveMasonry columnsCountBreakPoints={{0: 1, 991: 2}}>
                <Masonry>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("name")}:`}
                            value={robotData.summary.name}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("generation")}:`}
                            value={robotData.summary.generation ? robotData.summary.generation : t("unknown")}
                        />
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("status")}:`}
                            value={robotData.summary.status ? t(`api:robot_status_list.${robotData.summary.status}`) : t("unknown")}
                        />
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("location")}:`}
                            value={robotData.summary.location}
                        />
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("thisWeek")}:`}
                            value={(() => {
                                if (!robotData.summary.thisWeek.length) return <>{t("na")}</>;
                                const deployments = robotData.summary.thisWeek.reduce((acc, e, index) => {
                                    if (index > 0) {
                                        acc.push(", ");
                                    }
                                    acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}}
                                                             text={e.name} key={uniqueId()}/>);
                                    return acc;
                                }, []);
                                if (!deployments) return <>{t("na")}</>
                                return <>{deployments}</>;
                            })()}
                        />
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("nextWeek")}:`}
                            value={(() => {
                                if (!robotData.summary.nextWeek.length) return <>{t("na")}</>;
                                const deployments = robotData.summary.nextWeek.reduce((acc, e, index) => {
                                    if (index > 0) {
                                        acc.push(", ");
                                    }
                                    acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}}
                                                             text={e.name} key={uniqueId()}/>);
                                    return acc;
                                }, []);
                                return <>{deployments}</>;
                            })()}
                        />


                        <div className="d-flex mt-2">
                            <div className="fw-bold me-1">{`${t("assignee")}:`}</div>
                            {robotDataLoaded ? (
                                editingAssignee || !loadedAssignee ? (
                                    <>
                                        <div className="flex-grow-1 text-color-med">
                                            <StaffSelector loaded={loadedAssignee}
                                                           selectedStaff={assignee}
                                                           setSelectedStaff={setAssignee}
                                                           allowNone={true}
                                            />
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                onClick={() => {
                                                    if (!loadedAssignee) return;
                                                    setLoadedAssignee(false);
                                                    setEditingAssignee(false);
                                                    updateRobot("assignee", assignee).then(() => {
                                                        setLoadedAssignee(true);
                                                    })
                                                }}
                                                className="clickable pe-2"
                                            />
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                onClick={() => {
                                                    setEditingAssignee(false);
                                                    setAssignee(robotData.summary.assignee.id);
                                                }}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="clickable flex-grow-1 text-color-med"
                                            onClick={() => setEditingAssignee(true)}
                                        >
                                            {!!robotData.summary.assignee.id && (
                                                <LinkWithParams baseUrl={routes.operator.path}
                                                                params={{id: robotData.summary.assignee.id}}
                                                                text={robotData.summary.assignee.name}/>
                                            )}
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                onClick={() => setEditingAssignee(true)}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                )
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>


                        <EditableTitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("notes")}:`}
                            value={robotData.summary.notes}
                            onSave={async (value) => {
                                return await updateRobot(`notes`, value);
                            }}
                        />
                        {/*<div className="mt-2">
                            <div className="fw-bold">{t("servicePacks")}:</div>
                            {robotDataLoaded ? (
                                <div className="text-color-med">
                                    {robotData.summary.servicePacks.length ? robotData.summary.servicePacks.join(`, `) : t("na")}
                                </div>
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>*/}
                        <div className="d-flex mt-2">
                            <ActionButton
                                action={() => {
                                    if (window.open(robotData.summary.slackLink)) return true;
                                    return false;
                                }}
                                size={24}
                                tooltipText={t("openSlackChannel")}
                                defaultIcon={faSlack}
                            />
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("network")}</div>
                        <TitleValueLine
                            loaded={networkDataLoaded}
                            title={`${t("isComputerTailscaleConfigured", {computer: "Splinter"})}:`}
                            value={networkData.splinter.configured ? t("yes") : t("no")}
                            className="mt-2"
                        />
                        <div>
                            {networkDataLoaded ? (
                                networkData.splinter.online ? (
                                    <TitleValueLine
                                        loaded={networkDataLoaded}
                                        title="Splinter:"
                                        value={t("online")}
                                    />
                                ) : (
                                    <TitleValueLine
                                        loaded={networkDataLoaded}
                                        title={`${t("computerLastSeen", {computer: "Splinter"})}:`}
                                        value={networkData.splinter.lastSeen ? timeAgo(networkData.splinter.lastSeen) : t("unknown")}
                                    />
                                )

                            ) : (
                                <TitleValueLine
                                    loaded={false}
                                    title="Splinter:"
                                />
                            )}
                        </div>
                        <TitleValueLine
                            loaded={networkDataLoaded}
                            title={`${t("computerIpv4", {computer: "Splinter"})}:`}
                            value={networkData.splinter.ipv4 ? networkData.splinter.ipv4 : t("unknown")}
                        />
                        <TitleValueLine
                            loaded={networkDataLoaded}
                            title={`${t("computerIpv6", {computer: "Splinter"})}:`}
                            value={networkData.splinter.ipv6 ? networkData.splinter.ipv6 : t("unknown")}
                        />
                        <TitleValueLine
                            loaded={networkDataLoaded}
                            title={`${t("isComputerTailscaleConfigured", {computer: "April"})}:`}
                            value={networkData.april.configured ? t("yes") : t("no")}
                            className="mt-2"
                        />
                        <div>
                            {networkDataLoaded ? (
                                networkData.april.online ? (
                                    <TitleValueLine
                                        loaded={networkDataLoaded}
                                        title="April:"
                                        value={t("online")}
                                    />
                                ) : (
                                    <TitleValueLine
                                        loaded={networkDataLoaded}
                                        title={`${t("computerLastSeen", {computer: "April"})}:`}
                                        value={networkData.april.lastSeen ? timeAgo(networkData.april.lastSeen) : t("unknown")}
                                    />
                                )

                            ) : (
                                <TitleValueLine
                                    loaded={false}
                                    title="April:"
                                />
                            )}
                        </div>
                        <TitleValueLine
                            loaded={networkDataLoaded}
                            title={`${t("computerIpv4", {computer: "April"})}:`}
                            value={networkData.april.ipv4 ? networkData.april.ipv4 : t("unknown")}
                        />
                        <TitleValueLine
                            loaded={networkDataLoaded}
                            title={`${t("computerIpv6", {computer: "April"})}:`}
                            value={networkData.april.ipv6 ? networkData.april.ipv6 : t("unknown")}
                        />
                        <div className="d-flex mt-2">
                            <ActionButton
                                action={() => {
                                    try {
                                        refreshNetworkData();
                                        return true;
                                    } catch {
                                        return false;
                                    }
                                }}
                                size={24}
                                tooltipText={t("refresh")}
                                defaultIcon={faRefresh}
                            />
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("maintenance")}</div>
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("status")}:`}
                            value={robotData.maintenance.status ? t(`api:robot_maintenance_status_list.${robotData.maintenance.status}`) : t("na")}
                            className="mt-2"
                        />
                        <div>
                            <div className="fw-bold">{t("notes")}:</div>
                            {robotDataLoaded ? (
                                <div className="text-color-med">
                                    {robotData.maintenance.notes ? robotData.maintenance.notes : t("na")}
                                </div>
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>
                        <div className="d-flex mt-2">
                            <LinkWithParams data-tooltip-id="maintenance-event-tooltip"
                                            baseUrl={routes.updateRobotMaintenanceStatus.path} params={{id: id}}>
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    style={{fontSize: "24px"}}
                                    className="clickable text-color-accent pe-1"
                                    fixedWidth
                                />
                            </LinkWithParams>
                            <Tooltip
                                id="maintenance-event-tooltip"
                                place="top"
                                content={t("update")}
                                style={tooltipStyles}
                            />
                            <LinkWithParams data-tooltip-id="maintenance-history-tooltip"
                                            baseUrl={routes.robotMaintenanceHistory.path} params={{id: id}}>
                                <FontAwesomeIcon
                                    icon={faHistory}
                                    style={{fontSize: "24px"}}
                                    className="clickable text-color-accent"
                                    fixedWidth
                                />
                            </LinkWithParams>
                            <Tooltip
                                id="maintenance-history-tooltip"
                                place="top"
                                content={t("viewHistory")}
                                style={tooltipStyles}
                            />
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("calibration")}</div>
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("status")}:`}
                            value={robotData.calibration.status ? t(`api:robot_calibration_status_list.${robotData.calibration.status}`) : t("na")}
                            className="mt-2"
                        />
                        <div>
                            <div className="fw-bold">{t("notes")}:</div>
                            {robotDataLoaded ? (
                                <div className="text-color-med">
                                    {robotData.calibration.notes ? robotData.calibration.notes : t("na")}
                                </div>
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>
                        <div className="d-flex mt-2">
                            <LinkWithParams data-tooltip-id="calibration-event-tooltip"
                                            baseUrl={routes.updateRobotCalibrationStatus.path} params={{id: id}}>
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    style={{fontSize: "24px"}}
                                    className="clickable text-color-accent pe-1"
                                    fixedWidth
                                />
                            </LinkWithParams>
                            <Tooltip
                                id="calibration-event-tooltip"
                                place="top"
                                content={t("update")}
                                style={tooltipStyles}
                            />
                            <LinkWithParams data-tooltip-id="maintenance-history-tooltip"
                                            baseUrl={routes.robotCalibrationHistory.path} params={{id: id}}>
                                <FontAwesomeIcon
                                    icon={faHistory}
                                    style={{fontSize: "24px"}}
                                    className="clickable text-color-accent"
                                    fixedWidth
                                />
                            </LinkWithParams>
                            <Tooltip
                                id="maintenance-history-tooltip"
                                place="top"
                                content={t("viewHistory")}
                                style={tooltipStyles}
                            />
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("hardware")}</div>
                        <div className="d-flex mt-2">
                            <div className="fw-bold me-1">{`${t("mobileProvider")}:`}</div>
                            {robotDataLoaded ? (
                                editingMobileProvider || !loadedMobileProvider ? (
                                    <>
                                        <div className="flex-grow-1 text-color-med">
                                            <MobileProviderSelector loaded={loadedMobileProvider}
                                                                    mobileProvider={mobileProvider}
                                                                    setMobileProvider={setMobileProvider}
                                            />
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                onClick={() => {
                                                    if (!loadedMobileProvider) return;
                                                    setLoadedMobileProvider(false);
                                                    setEditingMobileProvider(false);
                                                    updateRobot("mobileProvider", mobileProvider).then(() => {
                                                        setLoadedMobileProvider(true);
                                                    })
                                                }}
                                                className="clickable pe-2"
                                            />
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                onClick={() => {
                                                    setEditingMobileProvider(false);
                                                    setMobileProvider(robotData.hardware.mobileProvider);
                                                }}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            onClick={() => setEditingMobileProvider(true)}
                                            className="clickable flex-grow-1 text-color-med"
                                        >
                                            {robotData.hardware.mobileProvider}
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                onClick={() => setEditingMobileProvider(true)}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                )
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>
                        <EditableTitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("simCard")}:`}
                            value={robotData.hardware.simCardIccid}
                            onSave={async (value) => {
                                return await updateRobot(`simCardIccid`, value);
                            }}
                        />
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("uptime90Days")}:`}
                            value={robotData.hardware.uptimeLast90Days ? robotData.hardware.uptimeLast90Days : t("na")}
                            className="mt-2"
                        />
                        {/*<TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("lastPrinterClean")}:`}
                            value={robotData.hardware.lastPrinterClean ? robotData.hardware.lastPrinterClean : t("na")}
                        />
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("lastPerformanceCheck")}:`}
                            value={robotData.hardware.lastPerformanceCheck ? robotData.hardware.lastPerformanceCheck : t("na")}
                        />*/}
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("software")}</div>
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("computerVersion", {computer: "Splinter"})}:`}
                            value={robotData.software.splinterSoftwareVersion ? robotData.software.splinterSoftwareVersion : t("unknown")}
                            className="mt-2"
                        />
                        <div>
                            <div className="fw-bold">{t("computerOverlaidRosPackages", {computer: "Splinter"})}:</div>
                            {robotDataLoaded ? (
                                <div className="d-flex flex-wrap text-color-med">
                                    {robotData.software.splinterOverlaidPackages.length ? processOverlaidPackages(robotData.software.splinterOverlaidPackages) : t("na")}
                                </div>
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>
                        <TitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("computerVersion", {computer: "April"})}:`}
                            value={robotData.software.aprilSoftwareVersion ? robotData.software.aprilSoftwareVersion : t("unknown")}
                            className="mt-2"
                        />
                        <div>
                            <div className="fw-bold">{t("computerOverlaidRosPackages", {computer: "April"})}:</div>
                            {robotDataLoaded ? (
                                <div className="d-flex flex-wrap text-color-med">
                                    {robotData.software.aprilOverlaidPackages.length ? processOverlaidPackages(robotData.software.aprilOverlaidPackages) : t("na")}
                                </div>
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("computerFirmware", {computer: "Splinter"})}</div>
                        <div className="mt-2">
                            {robotDataLoaded ? (
                                <>
                                    {Object.entries(robotData.splinterFirmware).map(([device, fw], index) => (
                                        <TitleValueLine
                                            key={index}
                                            loaded={true}
                                            title={`${camelCaseToWords(device)}:`}
                                            value={fw ? processRuggedFirmware(device, fw) : t("unknown")}
                                        />
                                    ))}
                                </>
                            ) : (
                                <LoadingSpinner size={48}/>
                            )}
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("computerFirmware", {computer: "April"})}</div>
                        <div className="mt-2">
                            {robotDataLoaded ? (
                                <>
                                    {Object.entries(robotData.aprilFirmware).map(([device, fw], index) => (
                                        <TitleValueLine
                                            key={index}
                                            loaded={true}
                                            title={`${camelCaseToWords(device)}:`}
                                            value={fw ? processRuggedFirmware(device, fw) : t("unknown")}
                                        />
                                    ))}
                                </>
                            ) : (
                                <LoadingSpinner size={48}/>
                            )}
                        </div>
                    </Card>
                    <></>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("miscellaneousFirmware")}</div>
                        <div className="mt-2">
                            {robotDataLoaded ? (
                                <>
                                    {Object.entries(robotData.miscFirmware).map(([device, fwVersion], index) => (
                                        <TitleValueLine
                                            key={index}
                                            loaded={true}
                                            title={`${camelCaseToWords(device)}:`}
                                            value={fwVersion ? fwVersion : t("unknown")}
                                        />
                                    ))}
                                </>
                            ) : (
                                <LoadingSpinner size={48}/>
                            )}
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("storageLocation")}</div>
                        <EditableTitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("address")}:`}
                            value={robotData.storageLocation.address}
                            onSave={async (value) => {
                                return await updateRobot(`storageAddress`, value);
                            }}
                            className="mt-2"
                        />
                        <EditableTitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("city")}:`}
                            value={robotData.storageLocation.city}
                            onSave={async (value) => {
                                return await updateRobot(`storageCity`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("state")}:`}
                            value={robotData.storageLocation.state}
                            onSave={async (value) => {
                                return await updateRobot(`storageState`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("zip")}:`}
                            value={robotData.storageLocation.zip}
                            onSave={async (value) => {
                                return await updateRobot(`storageZip`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={robotDataLoaded}
                            title={`${t("country")}:`}
                            value={robotData.storageLocation.country}
                            onSave={async (value) => {
                                return await updateRobot(`storageCountry`, value);
                            }}
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={robotDataLoaded}
                            title={t("upcomingDeployments")}
                            columnNames={assignmentsTableHeaders}
                            data={processFutureDeployments(robotData.upcomingDeployments)}
                        />
                    </Card>
                </Masonry>
            </ResponsiveMasonry>
            <PageTitle>
                {robotDataLoaded ? (
                    t("robotHistoricalData", {robot: robotData.summary.name})
                ) : (
                    <LoadingSpinner size={46}/>
                )}
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg mb-3">{t("schedule")}</div>
                        <Calendar loaded={robotDataLoaded} startDate={robotData.schedule.startDate}
                                  endDate={robotData.schedule.endDate}
                                  rows={robotData.schedule.robots}
                                  events={processCalendarData(robotData.schedule.robotSchedule)}
                                  initialDate={lastSunday}/>
                        <RobotCalendarLegend/>
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={robotDataLoaded}
                            title={t("pastDeployments")}
                            columnNames={assignmentsTableHeaders}
                            data={processPastDeployments(robotData.pastDeployments)}
                        />
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg mb-3">{t("historicalStatusChart")}</div>
                        {robotDataLoaded ? (
                            <ResponsiveContainer width="100%" height={384}>
                                <PieChart>
                                    <Pie
                                        data={processHistoricalStatusProportionData(robotData.historicalStatusProportions)}
                                        dataKey="value"
                                        nameKey="name"
                                        innerRadius={64}
                                    >
                                        {
                                            processHistoricalStatusProportionData(robotData.historicalStatusProportions).map((entry, index) => (
                                                <Cell key={`cell-${index}`}
                                                      fill={stateColors[entry.name]}/>
                                            ))
                                        }
                                    </Pie>
                                    <RechartsTooltip
                                        content={<HistoricalStatusProportionsTooltip/>}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        ) : (
                            <LoadingSpinner size={48}></LoadingSpinner>
                        )}
                        <RobotPieLegend />
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Robot };