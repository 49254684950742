import React, { useEffect, useMemo, useRef, useState } from "react";
import { Layer, Line, Rect, Stage, Text } from "react-konva";
import { addDays, differenceInDays, format, getDay, max, min, parseISO, startOfDay } from "date-fns";
import { useNavigate } from "react-router-dom";
import { colors, halfColor, LoadingSpinner, textColorForBackground, tooltipStyles } from "components/utils/ui";
import { uniqueId } from "helpers/random";
import { Tooltip } from "react-tooltip";
import { today } from "helpers/date";

const Calendar = ({
                      loaded,
                      startDate,
                      endDate,
                      cellWidth = 40,
                      rows,
                      rowLinkFunction,
                      events,
                      initialDate = "",
                      highlightStart = today(),
                      highlightEnd = today(),
                      containerRef: externalContainerRef
                  }) => {
    const navigate = useNavigate();

    const [containerWidth, setContainerWidth] = useState(0);
    const internalContainerRef = useRef(null);
    const containerRef = externalContainerRef || internalContainerRef;
    const [crossPattern, setCrossPattern] = useState(null);
    const [blankPattern, setBlankPattern] = useState(null);

    useEffect(() => {

        /*const svgPattern = `
      <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10">
        <rect width="5" height="10" fill="${colors.white}" />
        <line x1="0" y1="10" x2="5" y2="0" stroke="${colors.successSubtle}" stroke-width="0.5"/>
      </svg>`;
        const img = new Image();
        img.src = `data:image/svg+xml;base64,${btoa(svgPattern)}`;
        img.onload = () => {
            setSlashesPattern(img);
        };*/

        const svgCrossPattern = `
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
        <rect width="10" height="10" fill="${colors.white}" />
        <line x1="0" y1="0" x2="10" y2="10" stroke="${colors.successSubtle}" stroke-width="0.5"/>
        <line x1="0" y1="10" x2="10" y2="0" stroke="${colors.successSubtle}" stroke-width="0.5"/>
      </svg>`;
        const img2 = new Image();
        img2.src = `data:image/svg+xml;base64,${btoa(svgCrossPattern)}`;
        img2.onload = () => {
            setCrossPattern(img2);
        };

        const svgBlankPattern = `
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
        <rect width="10" height="10" fill="${colors.white}" />
      </svg>`;
        const img3 = new Image();
        img3.src = `data:image/svg+xml;base64,${btoa(svgBlankPattern)}`;
        img3.onload = () => {
            setBlankPattern(img3);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            let today;
            if (!!initialDate) today = startOfDay(new Date(initialDate + "T00:00:00"));
            else today = startOfDay(new Date());
            const daysFromStart = differenceInDays(today, parseISO(startDate));
            containerRef.current.scrollLeft = daysFromStart * cellWidth;
        }
    }, [containerWidth, startDate, cellWidth]);

    const totalDays = differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
    const totalRows = Object.keys(rows).length;
    const boxWidth = cellWidth;
    const boxHeight = 40;
    const gridHeight = (totalRows + 1) * boxHeight;

    const labels = useMemo(() => {
        const start = startOfDay(parseISO(startDate));
        return Array.from({length: totalDays}, (_, i) => ({
            date: format(addDays(start, i), "M/dd"),
            dayOfWeek: format(addDays(start, i), "EEE"),
            dayIndex: getDay(addDays(start, i)) // Get the index of the day (0 for Sunday, 6 for Saturday)
        }));
    }, [startDate, totalDays]);

    const getEventPosition = (date) => {
        const start = startOfDay(parseISO(startDate));
        const daysFromStart = differenceInDays(parseISO(date), start);
        return daysFromStart * boxWidth;
    };

    const navigateToUrl = (url) => {
        if (!!url) navigate(url);
    };

    const [tooltipId, setTooltipId] = useState("");

    useEffect(() => {
        setTooltipId(`tooltip-${uniqueId()}`);
    }, []);

    const todayPosition = useMemo(() => {
        const today = startOfDay(new Date());
        const startDay = startOfDay(parseISO(startDate));
        const daysFromStart = differenceInDays(today, startDay);
        return daysFromStart >= 0 && daysFromStart < totalDays ? daysFromStart * boxWidth : null;
    }, [startDate, totalDays, boxWidth]);

    const highlight = useMemo(() => {
        const highlightStartDay = startOfDay(parseISO(highlightStart));
        const highlightEndDay = startOfDay(parseISO(highlightEnd));
        const startDay = startOfDay(parseISO(startDate));

        const daysFromStartToHighlightStart = differenceInDays(highlightStartDay, startDay);
        const daysFromHighlightStartToEnd = differenceInDays(highlightEndDay, highlightStartDay);

        const startPosition = daysFromStartToHighlightStart >= 0 && daysFromStartToHighlightStart < totalDays ? daysFromStartToHighlightStart * boxWidth : null;
        const width = startPosition !== null && daysFromHighlightStartToEnd >= 0 ? (daysFromHighlightStartToEnd + 1) * boxWidth : null;

        return {startPosition, width};
    }, [highlightStart, highlightEnd, startDate, totalDays, boxWidth]);

    const gridLines = useMemo(() => {
        const lines = [];
        const start = startOfDay(parseISO(startDate));
        for (let i = 0; i <= totalDays; i++) {
            const dayIndex = getDay(addDays(start, i));
            const strokeWidth = dayIndex === 0 ? 4 : 1;
            lines.push({x: i * boxWidth, y: 0, x2: i * boxWidth, y2: gridHeight, strokeWidth});
        }
        for (let i = 0; i <= totalRows + 1; i++) {
            lines.push({x: 0, y: i * boxHeight, x2: totalDays * boxWidth, y2: i * boxHeight, strokeWidth: 1});
        }
        return lines;
    }, [totalDays, totalRows, boxWidth, boxHeight, gridHeight, startDate]);

    return (
        loaded ? (
            <div className="d-flex w-100">
                <div className="col-2 d-flex flex-column position-sticky start-0 bg-white"
                     style={{zIndex: 1, maxWidth: "50%"}}>
                    <div style={{height: boxHeight, width: boxWidth}}></div>
                    {Object.entries(rows).map(([rowId, rowDisplayName], rowIndex) => (
                        <div
                            className="pe-2 text-end"
                            key={rowId}
                            style={{height: boxHeight}}
                        >
                            <div
                                data-tooltip-id={`${tooltipId}-${rowId}`}
                                style={{
                                    display: "inline-block",
                                    height: boxHeight,
                                    lineHeight: `${boxHeight}px`,
                                    maxWidth: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {!!rowLinkFunction ? (
                                    rowLinkFunction(rowId, rowDisplayName)
                                ) : (
                                    rowDisplayName
                                )}
                            </div>
                            <Tooltip
                                offset={0}
                                id={`${tooltipId}-${rowId}`}
                                place="top"
                                content={rowDisplayName}
                                style={tooltipStyles}
                            />
                        </div>
                    ))}
                </div>
                <div ref={containerRef} style={{overflowX: "auto", width: "100%", touchAction: "pan-x"}}>
                    <Stage
                        width={totalDays * boxWidth}
                        height={gridHeight}
                        pixelRatio={0.015625}
                    >
                        <Layer listening={false}>
                            {highlight.startPosition !== null && (
                                <Rect
                                    x={highlight.startPosition}
                                    y={0}
                                    width={highlight.width}
                                    height={gridHeight}
                                    fill={colors.lightXxtrans}
                                    preventDefault={false}
                                />
                            )}
                            {gridLines.map((line, i) => (
                                <Line
                                    key={i}
                                    points={[line.x, line.y, line.x2, line.y2]}
                                    stroke={colors.lightXtrans}
                                    strokeWidth={line.strokeWidth}
                                />
                            ))}
                            {labels.map((label, i) => (
                                <Text
                                    key={`label-${i}`}
                                    x={i * boxWidth}
                                    y={0}
                                    width={boxWidth}
                                    height={boxHeight}
                                    text={`${label.dayOfWeek}\n${label.date}`}
                                    align="center"
                                    verticalAlign="middle"
                                    fontSize={12}
                                    preventDefault={false}
                                />
                            ))}
                        </Layer>
                        <Layer>
                            {events.map((event, eventIndex) => {
                                const travelStartX = event.travelStartDate ? Number(max([0, getEventPosition(event.travelStartDate)])) : null;
                                const eventStartX = Number(max([0, getEventPosition(event.startDate)]));
                                const eventEndX = Number(min([getEventPosition(event.endDate) + boxWidth, totalDays * boxWidth]));
                                const travelEndX = event.travelEndDate ? Number(min([getEventPosition(event.travelEndDate) + boxWidth, totalDays * boxWidth])) : null;

                                const eventWidth = eventEndX - eventStartX;
                                const travelStartWidth = travelStartX !== null ? eventStartX - travelStartX : 0;
                                const travelEndWidth = travelEndX !== null ? travelEndX - eventEndX : 0;

                                const rowIndex = Object.keys(rows).indexOf(event.row);
                                if (rowIndex === -1) return null;
                                const travelColor = halfColor(event.color)//`${event.color}80`;
                                const textColor = event.textColor ? event.textColor : (event.color ? textColorForBackground(event.color) : colors.white);

                                const outlineWidth = 3;

                                const handleClick = (e) => {
                                    if (e.evt.button !== 2 && !e.evt.ctrlKey) navigate(event.url); //regular navigate if not right click or ctrl + click
                                };
                                const handleMouseDown = (e) => {
                                    if (e.evt.button === 1) window.open(event.url, "_blank"); //middle click
                                    if (e.evt.ctrlKey && e.evt.button === 0) window.open(event.url, "_blank"); //control click
                                };

                                return (
                                    <React.Fragment key={eventIndex}>
                                        {travelStartX !== null && travelStartWidth > 0 && (
                                            <Rect
                                                x={travelStartX}
                                                y={(rowIndex + 1) * boxHeight}
                                                width={travelStartWidth}
                                                height={boxHeight}
                                                fill={travelColor}
                                                stroke={colors.white}
                                                strokeWidth={1}
                                                preventDefault={false}
                                            />
                                        )}
                                        {travelEndX !== null && travelEndWidth > 0 && (
                                            <Rect
                                                x={eventEndX}
                                                y={(rowIndex + 1) * boxHeight}
                                                width={travelEndWidth}
                                                height={boxHeight}
                                                fill={travelColor}
                                                stroke={colors.white}
                                                strokeWidth={1}
                                                preventDefault={false}
                                            />
                                        )}
                                        {!!event.pattern ? (
                                            event.pattern === calendarPatterns.cross ? (
                                                <Rect
                                                    x={eventStartX + 1}
                                                    y={(rowIndex + 1) * boxHeight + 1}
                                                    width={eventWidth - 2}
                                                    height={boxHeight - 2}
                                                    fillPatternImage={crossPattern}
                                                    fillPatternScale={{x: 1, y: 1}}
                                                    fillPatternRepeat="repeat"
                                                    stroke={colors.white}
                                                    strokeWidth={1}
                                                    preventDefault={false}
                                                />
                                            ) : event.pattern === calendarPatterns.blank ? (
                                                <Rect
                                                    x={eventStartX + 1}
                                                    y={(rowIndex + 1) * boxHeight + 1}
                                                    width={eventWidth - 2}
                                                    height={boxHeight - 2}
                                                    fillPatternImage={blankPattern}
                                                    fillPatternScale={{x: 1, y: 1}}
                                                    fillPatternRepeat="repeat"
                                                    stroke={colors.white}
                                                    strokeWidth={1}
                                                    preventDefault={false}
                                                />
                                            ) : null
                                        ) : (
                                            <Rect
                                                x={eventStartX}
                                                y={(rowIndex + 1) * boxHeight}
                                                width={eventWidth}
                                                height={boxHeight}
                                                fill={event.color}
                                                stroke={colors.white}
                                                strokeWidth={1}
                                                preventDefault={false}
                                            />
                                        )}
                                        {event.outlineColor && (
                                            <Rect
                                                x={eventStartX + outlineWidth / 2 + 1}
                                                y={(rowIndex + 1) * boxHeight + outlineWidth / 2 + 1}
                                                width={eventWidth - outlineWidth - 2}
                                                height={boxHeight - outlineWidth - 2}
                                                onClick={handleClick}
                                                onMouseDown={handleMouseDown}
                                                onMouseEnter={e => {
                                                    if (!!event.url) {
                                                        const container = e.target.getStage().container();
                                                        container.style.cursor = "pointer";
                                                    }
                                                }}
                                                onMouseLeave={e => {
                                                    const container = e.target.getStage().container();
                                                    container.style.cursor = "default";
                                                }}
                                                stroke={event.outlineColor}
                                                strokeWidth={outlineWidth + 1}
                                                preventDefault={false}
                                            />
                                        )}
                                        {!!event.name && (
                                            <Text
                                                x={event.outlineColor ? eventStartX + outlineWidth / 2 + 1 : eventStartX}
                                                y={event.outlineColor ? (rowIndex + 1) * boxHeight + outlineWidth / 2 + 1 : (rowIndex + 1) * boxHeight}
                                                width={event.outlineColor ? eventWidth - outlineWidth - 2 : eventWidth}
                                                height={event.outlineColor ? boxHeight - outlineWidth - 2 : boxHeight}
                                                text={event.name}
                                                align="center"
                                                verticalAlign="middle"
                                                fontSize={14}
                                                fontStyle="bold"
                                                fontFamily="Public Sans"
                                                fill={textColor}
                                                className="clickable"
                                                onClick={handleClick}
                                                onMouseDown={handleMouseDown}
                                                onMouseEnter={e => {
                                                    if (!!event.url) {
                                                        const container = e.target.getStage().container();
                                                        container.style.cursor = "pointer";
                                                    }
                                                }}
                                                onMouseLeave={e => {
                                                    const container = e.target.getStage().container();
                                                    container.style.cursor = "default";
                                                }}
                                                preventDefault={false}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Layer>
                    </Stage>
                </div>
            </div>
        ) : (
            <LoadingSpinner size={48}></LoadingSpinner>
        )
    );
};

const calendarPatterns = {
    //"slash": "slash",
    "cross": "cross",
    "blank": "blank"
}

export { Calendar, calendarPatterns };
