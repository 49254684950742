import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Calendar,
    Card,
    ExternalLink,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    SearchableList,
    styleCalendarData,
    TitleValueLine
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "helpers/routes";
import {
    convertToPrettyDate,
    convertToPrettyDateWithYear,
    dateObjectToDateString,
    getLastSundayDateObject
} from "helpers/date";
import { formatPhoneNumber } from "helpers/phone";
import { uniqueId } from "helpers/random";
import { useHotkeys } from "react-hotkeys-hook";

const Project = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const lastSunday = dateObjectToDateString(getLastSundayDateObject());

    useHotkeys("esc", () => navigate(routes.projects.path));

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.project;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const projectContactsTableHeaders = {
        name: t("name"),
        phone: t("phone"),
        email: t("email")
    }

    const processProjectContacts = (contacts) => {
        return contacts.map(contact => {
            const formattedPhone = formatPhoneNumber(contact.phone);
            return {
                name: contact.name,
                email: contact.email ? (<a className="clickable text-color-accent"
                                           href={`mailto:${contact.email}`}>{contact.email}</a>) : "",
                phone: formattedPhone ? (<a className="clickable text-color-accent"
                                            href={`tel:${formattedPhone}`}>{formattedPhone}</a>) : "",
            };
        });
    };

    const projectCompaniesTableHeaders = {
        name: t("name"),
        domain: t("website"),
        phone: t("phone")
    }

    const processProjectCompanies = (companies) => {
        return companies.map(company => {
            const formattedPhone = formatPhoneNumber(company.phone);
            const formattedDomain = company.domain ? (company.domain.startsWith(`http://`) || company.domain.startsWith(`https://`))
                    ? company.domain
                    : `http://${company.domain}`
                : ``;
            return {
                name: company.name,
                domain: formattedDomain ? (
                    <a className="clickable text-color-accent"
                       href={formattedDomain} target="_blank" rel="noopener noreferrer">
                        {company.domain}
                    </a>
                ) : "",
                phone: formattedPhone ? (
                    <a className="clickable text-color-accent"
                       href={`tel:${formattedPhone}`}>
                        {formattedPhone}
                    </a>
                ) : "",
            };
        });
    };


    const processCalendarData = (calendarData) => {
        return calendarData.map(e => styleCalendarData(e));
    };

    const deploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        location: t("location"),
        robots: t("robots"),
        staff: t("staff"),
        foremanAssigned: t("foremanAssigned"),
        confidenceLevel: t("confidence")
    }

    const processDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                    text={deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDate(deployment.travelStartDate)}-${convertToPrettyDate(deployment.travelEndDate)}`,
                location: deployment.location,
                robots: (() => {
                    const robots = deployment.robots.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.robot.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.robotsAssigned}/{deployment.robotsRequired}{!!deployment.robots.length && (<>&nbsp;({robots})</>)}
                        </div>
                    );
                })(),
                staff: (() => {
                    const staff = deployment.staff.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.operator.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.staffAssigned}/{deployment.staffRequired}{!!deployment.staff.length && (<>&nbsp;({staff})</>)}
                        </div>
                    );
                })(),
                foremanAssigned: deployment.staffRequired === 0 ? t("na") : (deployment.foremanAssigned ? t("yes") : t("no")),
                confidenceLevel: t(`api:deployment_confidence_level_list.${deployment.confidenceLevel}`)
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1" baseUrl={routes.projects.path}>{t("projects")}</PageBackButton>
                    {loaded ? (
                        data.summary.name
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("name")}:`}
                            value={data.summary.name}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("layoutStartDate")}:`}
                            value={convertToPrettyDateWithYear(data.summary.layoutStartDate)}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("location")}:`}
                            value={data.summary.location}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("projectType")}:`}
                            value={data.summary.type}
                        />
                        <ExternalLink
                            href={data.summary.url}
                            text={t("viewInHubspot")}
                            className="mt-2"
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("projectContacts")}
                            columnNames={projectContactsTableHeaders}
                            data={processProjectContacts(data.contacts)}
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("projectCompanies")}
                            columnNames={projectCompaniesTableHeaders}
                            data={processProjectCompanies(data.companies)}
                        />
                    </Card>
                    <Card>
                        <div className="d-flex mb-3">
                            <div className="flex-grow-1 fw-bold text-size-lg">{t("projectSchedule")}</div>
                        </div>
                        <Calendar loaded={loaded} startDate={data.schedule.startDate} endDate={data.schedule.endDate}
                                  rows={data.schedule.projects}
                                  events={processCalendarData(data.schedule.projectSchedule)}
                                  initialDate={lastSunday}/>
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("linkedDeployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.deployments)}
                        />
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Project };